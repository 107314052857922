<script lang="ts" setup>
import { useLocale } from "vuetify";

const { t } = useLocale();
</script>

<template>
  <section>
    <s-skew :angle="8" class="bg-white">
      <template #background>
        <!-- parallax arch (mobile) -->
        <div class="md:hidden relative h-full w-full">
          <s-parallax
            class="absolute h-1/2 left-1/4 top-1/4 opacity-40"
            overflow
            src="/images/graphics/rose-blue.svg">
          </s-parallax>
          <img
            class="absolute bottom-0 w-full"
            src="/images/history/arch.m.webp">
        </div>
        <!-- parallax arch (desktop) -->
        <div class="max-md:!hidden h-full ml-[50%] w-1/2 relative">
          <s-parallax
            class="h-full overflow-visible opacity-40"
            overflow
            src="/images/graphics/rose-blue.svg">
          </s-parallax>
          <img
            class="bottom-[14%] absolute w-full"
            src="/images/history/arch.webp">
        </div>
      </template>
      <!-- info box (mobile) -->
      <div class="md:hidden">
        <s-header
          class="my-12"
          :image='t("ourHistory.titleImage")'
          :title='t("ourHistory.title")'>
        </s-header>
        <div class="max-w-[60%] mx-[20%] mb-12 leading-loose ligh" v-html='t("ourHistory.description")'></div>
        <v-responsive :aspect-ratio="1.55"></v-responsive>
      </div>
      <!-- info box (desktop) -->
      <div class="max-md:hidden ml-[20%] mr-[50%] pt-16 pb-12">
        <div class="flex justify-start">
          <s-header
            class="-ml-4"
            :image='t("ourHistory.titleImage")'
            :title='t("ourHistory.title")'>
          </s-header>
          <div class="grow basis-0"></div>
        </div>
        <div class="mb-12 leading-loose" v-html='t("ourHistory.description")'></div>
      </div>
    </s-skew>
  </section>
</template>
