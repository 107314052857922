<script lang="ts" setup>
import { ref } from "vue";

defineProps(
{
  descriptionLeft: String,
  descriptionRight: String,
  imageLeft: String,
  imageRight: String,
  titleLeft: String,
  titleRight: String,
});

enum OpenState
{
  Left,
  None,
  Right,
}

const openState = ref(OpenState.None);
</script>

<template>
  <div class="grid grid-cols-2 gap-2 select-none">
    <div
      class="square group"
      :class='{ "open": openState === OpenState.Left }'
      @click="openState = openState === OpenState.Left ? OpenState.None : OpenState.Left"
      :style='`background-image: url("${imageLeft}");`'>
      <h3 v-html="titleLeft"></h3>
      <button
        @mouseover="openState = OpenState.Left"
        @mouseout="openState = OpenState.None">
        <i class="mdi mdi-chevron-down"></i>
      </button>
    </div>
    <div
      class="square group"
      :class='{ "open": openState === OpenState.Right }'
      @click="openState = openState === OpenState.Right ? OpenState.None : OpenState.Right"
      :style='`background-image: url("${imageRight}");`'>
      <h3 v-html="titleRight"></h3>
      <button
        @mouseover="openState = OpenState.Right"
        @mouseout="openState = OpenState.None">
        <i class="mdi mdi-chevron-down"></i>
      </button>
    </div>
  </div>
  <s-expand>
    <div
      v-if="openState !== OpenState.None">
      <div
        v-if="openState === OpenState.Left"
        class="expander"
        v-html="descriptionLeft">
      </div>
      <div
        v-if="openState === OpenState.Right"
        class="expander"
        v-html="descriptionRight">
      </div>
    </div>
  </s-expand>
</template>

<style scoped>
button
{
  @apply
    bg-severus-gold text-white text-right rounded-tl-sm
    absolute bottom-0 right-0 w-full max-w-[44px] h-6
    group-[.open]:max-w-full
    group-[.open]:-bottom-3
    group-[.open]:h-9
    group-[.open]:rounded-tl-none
    transition-all;
}

.expander
{
  @apply bg-white border-t-4 border-severus-gold p-6 shadow-md;
}

h3
{
  @apply text-white uppercase text-2xl font-semibold mx-[15%] mt-[35%] leading-[1.15];
}

i
{
  @apply mr-3.5 pointer-events-none;
}

.square
{
  @apply
    aspect-square rounded-t-sm relative
    bg-cover bg-center shadow-sm;
}
</style>
