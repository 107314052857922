<script lang="ts" setup>
import { languages } from "@/plugins/i18n";
import { useLocale } from "vuetify"; // TODO: OBSOLETE.
import { onMounted, onBeforeUnmount, ref } from "vue";

const { current, t } = useLocale();

const links =
[
  {
    href: "siteHeader.links.aboutLink",
    text: "siteHeader.links.about",
  },
  {
    href: "siteHeader.links.strategyLink",
    text: "siteHeader.links.strategy",
  },
  {
    href: "siteHeader.links.portfolioLink",
    text: "siteHeader.links.portfolio",
   },
  {
    href: "siteHeader.links.teamLink",
    text: "siteHeader.links.team",
   },
  // {
  //   href: "siteHeader.links.newsLink",
  //   text: "siteHeader.links.news",
  // },
  // {
  //   href: "siteHeader.links.downloadsLink",
  //   text: "siteHeader.links.downloads",
  // },
  {
    href: "siteHeader.links.contactLink",
    text: "siteHeader.links.contact",
  },
];

const showNavBar = ref(true);
const showDrawer = ref(false);

const wipList = ref<string[]>([]);
const wipShow = ref(false);

let lastScrollY = -1;

const onScroll = () =>
{
  const scrollY = window.scrollY || document.documentElement.scrollTop;

  // Ignore scroll momentum on mobile.
  if (scrollY < 0)
  {
    return;
  }

  // Ignore if less than threshold.
  if (Math.abs(scrollY - lastScrollY) < 200)
  {
    return;
  }

  showNavBar.value = scrollY < lastScrollY;
  lastScrollY = scrollY;
};

onMounted(() =>
{
  window.addEventListener("scroll", onScroll);
});

onBeforeUnmount(() =>
{
  window.removeEventListener("scroll", onScroll);
});
</script>

<template>
  <div class="h-24 lg:h-20 bg-white"></div>
  <header
    class="
      flex items-center
      w-full h-24 px-6 lg:h-20 transition-[top]
      fixed z-[10000]
      backdrop-blur bg-white/90
      border-b border-black/30"
    :class='{
      "top-0": (showNavBar || showDrawer),
      "max-lg:-top-24": !(showNavBar || showDrawer) }'>
    <img
      alt="LH Severus logo"
      class="py-2 h-full"
      src="/images/lh-severus-logo.svg">
    <!-- narrow display -->
    <div class="flex lg:hidden grow">
      <div class="grow"></div>
      <s-menu-button @click="showDrawer = !showDrawer" :toggle="showDrawer"></s-menu-button>
    </div>
    <!-- wide display -->
    <div class="hidden lg:flex grow items-center">
      <div class="mx-6">
        <button
          v-for="language in languages.filter(l => l.code !== current)"
          class="
            text-white bg-black h-12 px-4 py-2 mx-0.5 rounded-sm
            hover:!bg-severus-blue transition-[background-color]"
          :class='`
            lang-${language.code}
            ${language.code === "ar" ? "text-2xl" : ""}`'
          @click='() =>
          {
            current = language.code;
            if (wipList.includes(language.code))
            {
              wipShow = true;
            }
          }'
          :key="language.code">
          {{ language.name }}
        </button>
      </div>
      <div class="grow"></div>
      <nav
        :class='current === "ar"
          ? "[&>:not(:first-child)]:border-r"
          : "[&>:not(:first-child)]:border-l"'>
        <a
          class="
            swipe px-5 border-black/30
            before:bg-severus-blue before:rounded-sm
            hover:text-white transition-colors"
          :href="`#${t(link.href)}`"
          :key="link.href"
          v-for="link in links">
          <span>{{ t(link.text) }}</span>
        </a>
      </nav>
    </div>
  </header>
  <div
    class="
      w-full h-full transition-[top] pt-24 lg:pt-20
      fixed z-[1000]"
    :class='{
      "top-0": showDrawer,
      "top-full": !showDrawer}'>
    <div
      class="w-full h-full backdrop-blur bg-white/90 overflow-auto">
      <div class="flex justify-center m-4">
        <button
          class="
          text-white bg-black text-2xl hover:!bg-severus-blue transition-[background-color]
            px-14 py-3 m-1 font-light rounded-sm"
          :class='`
            lang-${language.code}
            ${language.code === "ar" ? "text-3xl" : ""}`'
          @click='() =>
          {
            current = language.code;
            showDrawer = false;
            if (wipList.includes(language.code))
            {
              wipShow = true;
            }
          }'
          :key="language.code"
          v-for="language in languages.filter(l => l.code !== current)">
          {{ language.name }}
        </button>
      </div>
      <nav class="my-8">
        <a
          class="
            w-full block text-center p-4 text-2xl font-light
            hover:bg-black/10 target:italic"
          @click="() => { showDrawer = false; }"
          :href="`#${t(link.href)}`"
          :key="link.href"
          v-for="link in links">
          {{ t(link.text) }}
      </a>
      </nav>
    </div>
  </div>
  <v-dialog v-model="wipShow">
    <div
      class="construction"
      :class='{ "text-right": current === "ar" }'>
      <div class="lang-ar" v-if='current === "ar"'>
        الموقع العربي قيد التقدم. أرجوك عد قريبا.
      </div>
      <div class="lang-it" v-if='current === "it"'>
        Il sito italiano è un work in progress.<br>Per favore torna presto.
      </div>
      <v-btn class="mt-8" @click="wipShow = false" color="black">
        <div class="lang-ar" v-if='current === "ar"'>
          رفض
        </div>
        <div class="lang-it" v-if='current === "it"'>
          Congedare
        </div>
      </v-btn>
    </div>
  </v-dialog>
</template>
