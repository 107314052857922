<script lang="ts" setup>
import { useLocale } from "vuetify";

const { t } = useLocale();
</script>

<template>
  <video-banner></video-banner>
  <about-us :id='t("siteHeader.links.aboutLink")' class="about-us"></about-us>
  <our-history class="our-history"></our-history>
  <why-libya class="why-libya"></why-libya>
  <our-strategy :id='t("siteHeader.links.strategyLink")'></our-strategy>
  <portfolio-companies :id='t("siteHeader.links.portfolioLink")'></portfolio-companies>
  <our-team :id='t("siteHeader.links.teamLink")'></our-team>
  <contact-us :id='t("siteHeader.links.contactLink")'></contact-us>
  <site-footer></site-footer>
</template>
