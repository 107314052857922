<script lang="ts" setup>
import { computed, ref } from "vue";
import { useDisplay, useLocale } from "vuetify";

const { mobile } = useDisplay();
const { current, t } = useLocale();

const mOrD = computed(() => `videoCarousel.${ mobile.value ? "mobile" : "desktop" }`);
const bannerList = computed(() => t(`${mOrD.value}.list`).split(","));
const carouselModel = ref(0);

const interval = 5000;
</script>

<template>
  <div
    class='
      cover text-white max-md:hidden text-2xl text-center py-8 flex
      bg-[url("/images/general/blue-banner.webp")] bg-cover bg-bottom'
    v-if="!mobile">
    <div class="mx-auto" v-html='t("videoCarousel.banner")'></div>
  </div>
  <v-responsive
    :aspect-ratio="mobile ? (1080 / 1650) : (2000 / 900)"
    :class='{ mobile }'>
    <v-carousel
      class="carousel"
      color="white"
      cycle
      delimiter-icon="mdi-circle-outline"
      height="100%"
      :hide-delimiters="mobile"
      hide-delimiter-background
      :interval="interval"
      :show-arrows="false"
      v-model:model-value="carouselModel">      
      <v-carousel-item
        v-for="banner in bannerList"
        :key="banner">

        <video
        class="bg-black h-full w-full absolute"
        autoplay
        loop
        defaultMuted
        muted
        playsinline
        preload=""
        :src='`/videos/banners/${t(`${mOrD}.${banner}.video`)}`'>
      </video>

        <div class="absolute h-full w-full">
          <img
            class="absolute w-full -bottom-[2.5%]"
            :src="`/images/banners/${t(`${mOrD}.${banner}.image`)}`">
          <v-btn
            class="!absolute !rounded-sm px-8"
            :color="t(`${mOrD}.${banner}.button.color`)"
            :href="`#${banner}`"
            :style="`
              left: ${t(`${mOrD}.${banner}.button.left`)};
              right: ${t(`${mOrD}.${banner}.button.right`)};
              top: ${t(`${mOrD}.${banner}.button.top`)};`"
            :variant="(t(`${mOrD}.${banner}.button.variant`) as any)">
            <span class="font-weight-medium">{{ t("videoCarousel.readMore") }}</span>
          </v-btn>
        </div>
      </v-carousel-item>
    </v-carousel>
  </v-responsive>
</template>

<style scoped>
.carousel :deep()
{
  .v-carousel__controls .v-btn--active .v-btn__overlay
  {
    @apply opacity-100 w-[13px] h-[13px] top-[9px] left-[9px];
  }

  .v-window-x-reverse-transition-enter-active,
  .v-window-x-reverse-transition-leave-active,
  .v-window-x-transition-enter-active,
  .v-window-x-transition-leave-active
  {
    @apply !transition-none;
  }
}
</style>
