<script lang="ts" setup>
import { ref } from "vue";
import { useLocale } from "vuetify";

const { t } = useLocale();

const active = ref(-1);

const whyLibyaReasons = ref(
[
  {
    img: "oil-country.webp",
    key: "oilCountry",
  },
  {
    img: "broad-and-deep.webp",
    key: "broadAndDeep",
  },
  {
    img: "low-competition.webp",
    key: "lowCompetition",
  },
  {
    img: "strategic-location.webp",
    key: "strategicLocation",
  },
]);
</script>

<template>
  <section
    class='
      main max-md:pb-8 md:pb-32
      bg-[url("/images/why-libya/map.webp")] md:bg-cover md:bg-center'
    @click="active = -1"
    @mouseover="active = -1">
    <v-responsive :aspect-ratio="7.14"></v-responsive>
    <s-header
      class="max-md:my-10 md:mt-12 md:mb-32"
      :image='t("whyLibya.titleImage")'
      :title='t("whyLibya.title")'>
    </s-header>
    <!-- expanders (mobile) -->
    <div class="p-4 grid gap-2 md:hidden">
      <s-duo-expander
        :description-left="t(`whyLibya.reasons.oilCountry.description`)"
        :description-right="t(`whyLibya.reasons.broadAndDeep.description`)"
        image-left="/images/why-libya/oil-country.webp"
        image-right="/images/why-libya/broad-and-deep.webp"
        :title-left="t(`whyLibya.reasons.oilCountry.title`)"
        :title-right="t(`whyLibya.reasons.broadAndDeep.title`)">
      </s-duo-expander>
      <s-duo-expander
        :description-left="t(`whyLibya.reasons.lowCompetition.description`)"
        :description-right="t(`whyLibya.reasons.strategicLocation.description`)"
        image-left="/images/why-libya/low-competition.webp"
        image-right="/images/why-libya/strategic-location.webp"
        :title-left="t(`whyLibya.reasons.lowCompetition.title`)"
        :title-right="t(`whyLibya.reasons.strategicLocation.title`)">
      </s-duo-expander>
    </div>
    <!-- accordion (desktop) -->
    <div class="mx-20 max-md:hidden" @mouseover.stop.prevent>
      <v-expansion-panels
        class="panels flex"
        variant="accordion"
        v-model:model-value="active">
        <v-expansion-panel
          class="panel bg-white flex h-[500px] m-1 relative transition-[flex]"
          :class='{ "active": active === i }'
          @mouseover="active = i"
          v-bind:key="reason.key"
          v-for="(reason, i) in whyLibyaReasons">
          <div
            class="
              background
              absolute bottom-0 left-0 right-0 top-0
              bg-cover bg-center opacity-100 transition-opacity"
            v-bind:style="`background-image: url('/images/why-libya/${reason.img}');`">
          </div>
          <div
            class="
              text-white bg-severus-gold
              absolute bottom-0 h-6 right-0 w-12
              text-center overflow-hidden rounded-tl-sm
              fake-button">
            <v-icon>mdi-chevron-down</v-icon>
          </div>
          <v-expansion-panel-title hide-actions ripple>
            <b
              class="text-white uppercase text-2xl font-semibold leading-[1.15]"
              v-html="t(`whyLibya.reasons.${reason.key}.title`)"></b>
          </v-expansion-panel-title>
          <v-expansion-panel-text
            class="
              absolute bottom-0 left-0 right-0 top-0
              flex items-center overflow-hidden">
            <div class="
              max-h-[500px] bg-white -mx-8 py-10 px-20 overflow-y-scroll">
              <h3
                class="
                  text-xl no-breaks-on-desktop no-breaks-on-mobile mb-4
                  text-severus-blue italic font-semibold"
                v-html="t(`whyLibya.reasons.${reason.key}.title`)">
              </h3>
              <div v-html="t(`whyLibya.reasons.${reason.key}.description`)">
              </div>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </section>
</template>

<style scoped>
.carousel :deep()
{
  .v-carousel__controls .v-btn--active .v-btn__overlay
  {
    @apply opacity-100 w-[13px] h-[13px] top-[9px] left-[9px];
  }

  .v-window__controls
  {
    @apply p-0;

    .v-window__left,
    .v-window__right
    {
      @apply text-white bg-severus-gold rounded-none w-6 shadow-none;
    }

.v-window__left
{
  @apply rounded-r-sm;
}

.v-window__right
{
  @apply rounded-l-sm;
}
  }
}

.panels
{
  .panel
  {
    flex: 1;

    &.active
    {
      flex: 2;

      .background
      {
        opacity: 0;
      }

      .fake-button
      {
        height: 0;
      }

      .panel-title *
      {
        display: none;
      }
    }
  }
}
</style>
