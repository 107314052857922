<script lang="ts" setup>
import { ref } from "vue";
import { useDisplay, useLocale } from "vuetify";

const { mobile } = useDisplay();
const { t } = useLocale();

const submitShow = ref(false);

const formData = ref({
  email: "",
  message: "",
  name: "",
  number: "",
  subject: "",
});

async function submitForm() {
  try {
    const apiKey = "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";
    const apiUrl = "https://api.brevo.com/v3/smtp/email";

    const sendData = {
      sender: {
        name: "LH Severus",
        email: "info@lhseverus.com"
      },
      to: [
        {
          email: "graham.beaton@lhseverus.com",
          name: "Graham",
        },
        {
          email: "david.edwards@lhseverus.com",
          name: "David",
        },
        {
          email: "info@lhseverus.com",
          name: "Info",
        }
      ],
      subject: formData.value.subject,
      htmlContent: `<html><head></head><body><p>Name: ${formData.value.name}</p><p>Email: ${formData.value.email}</p><p>Number: ${formData.value.number}</p><p>Message: ${formData.value.message}</p></body></html>`
    };

    const res = await fetch(apiUrl, {
      body: JSON.stringify(sendData),
      headers: {
        "accept": "application/json",
        "api-key": apiKey,
        "Content-Type": "application/json",
      },
      method: "POST",
    });

    // TODO: Check if false returned.

    // Clear form data
    formData.value = {
      email: "",
      message: "",
      name: "",
      number: "",
      subject: "",
    };

    // Show success message
    submitShow.value = true;
    setTimeout(() => submitShow.value = false, 5000);
  } catch (error) {
    alert(`There was a problem submitting the form: ${error}`);
  }
}
</script>

<template>
  <section class="relative bg-black">
    <s-parallax class="!absolute bottom-0 left-0 right-0 top-0" src="/images/backgrounds/15.webp">
    </s-parallax>
    <div class="
        bg-black/50 backdrop-blur-[2px] rounded-sm
        my-16 mx-[5%] md:mx-auto pt-2 pb-16 px-[5%] md:w-3/5 relative" :class='{ mobile }'>
      <template v-if="!mobile">
        <!-- <template class="max-sm:hidden"> -->
        <div class="a"></div>
        <div class="b"></div>
        <div class="c"></div>
        <div class="d"></div>
      </template>
      <s-header class="mb-2.5" :title='t("contactUs.title")' :image='t("contactUs.titleImage")'>
      </s-header>
      <form @submit.prevent="submitForm">
        <s-text-input class="mb-4" :label='t("contactUs.name")' name="name" v-model="formData.name">
        </s-text-input>
        <s-text-input class="mb-4" :label='t("contactUs.email")' name="email" v-model="formData.email">
        </s-text-input>
        <s-text-input class="mb-4" :label='t("contactUs.number")' name="number" v-model="formData.number">
        </s-text-input>
        <s-text-input class="mb-4" :label='t("contactUs.subject")' name="subject" v-model="formData.subject">
        </s-text-input>
        <s-text-input class="mb-4" :label='t("contactUs.message")' name="message" v-model="formData.message">
        </s-text-input>
        <button class="
            w-full text-white h-14 mt-6 rounded-sm
            bg-gradient-to-r from-severus-blue-light to-severus-blue" type="submit">
          {{ t("contactUs.send") }}
        </button>
      </form>
    </div>
    <div class="relative text-white bg-black/95 text-center py-6">
      <i class="mdi mdi-email-outline m-2"></i>
      <span class="italic font-bold">
        {{ t("contactUs.generalInquiries") }}
      </span>&ThickSpace;
      <span class="footer-email" v-html='t("contactUs.generalInquiriesAddress")'></span>
    </div>
    <v-dialog v-model="submitShow">
      <div class="bg-white text-2xl rounded-md relative self-center max-w-sm p-8">
        <h3>Thank you!</h3>
      </div>
    </v-dialog>
  </section>
</template>

<style scoped>
.a,
.b,
.c,
.d {
  position: absolute;
}

.a,
.b {
  border-bottom: 1px solid white;
  border-right: 1px solid white;
}

.c,
.d {
  border-left: 1px solid white;
  border-top: 1px solid white;
}

.a,
.c {
  height: 15px;
  width: 15px;
}

.b,
.d {
  height: 200px;
  width: 60px;
}

.a {
  left: -15px;
  top: -15px;
}

.b {
  bottom: -1px;
  right: -1px;
}

.c {
  top: 100%;
  left: 100%;
}

.d {
  left: -1px;
  top: -1px;
}

.mobile {
  .contact-wrapper {
    width: unset;
  }
}
</style>
