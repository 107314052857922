<script lang="ts" setup>
import { useLocale } from "vuetify";

const { t } = useLocale();
</script>

<template>
  <section
    class='
      main flex flex-col justify-center
      bg-[#f0f9ff]
      bg-[url("/images/about/city.m.webp")] max-md:-mb-8
      md:bg-[url("/images/about/city.webp")]
      after:aspect-[0.85] md:after:aspect-[1.7]'
    style="
      background-size: 100% auto;
      background-position: center bottom;">
    <s-header
      class="mt-16 mb-8"
      :image='t("aboutUs.titleImage")'
      :title='t("aboutUs.title")'>
    </s-header>
    <h3
      class="text-severus-blue text-2xl text-center mx-auto"
      v-html='t("aboutUs.subtitle")'>
    </h3>
    <br>
    <img
      class="block mx-auto mt-8 mb-12 h-8"
      src="/images/about/line-element.svg">
    <div class="accordion">
      <about-accordion class="mx-[5%] md:mx-auto md:max-w-[60%] md:w-full">
      </about-accordion>
    </div>
    <div
      class="
        text-severus-blue text-2xl
        mt-12 mx-[15%] md:mx-auto md:max-w-[40%]"
      v-html='t("aboutUs.description")'>
    </div>
  </section>
</template>
