<script lang="ts" setup>
import SMember from "@/components/SMember.vue";
import { ref } from "vue";
import { useLocale } from "vuetify";

const { t } = useLocale();

const showBackdrop = ref(false);
const lastExpanded = ref(-1);

const memberRefs = ref<InstanceType<typeof SMember>[] | null>(null);
</script>

<template>
  <section>
    <div
      class="
        fixed bottom-0 left-0 right-0 top-0 z-10 pointer-events-none
        backdrop-blur-[2px] bg-severus-blue/50 opacity-0 transition-opacity duration-200"
      :class='{ "opacity-100 !pointer-events-auto": showBackdrop }'
      @click="() =>
      {
        showBackdrop = false;
        memberRefs?.forEach(r =>
        {
          r.close();
        });
      }">
    </div>
    <s-header
      background="/images/general/blue-banner.webp"
      :image='t("ourTeam.titleImage")'
      :title='t("ourTeam.title")'>
    </s-header>
    <div
      class="flex flex-wrap justify-center p-2 bg-[#e6e6e6] md:py-24">
      <s-member
        :class='{ "z-10": lastExpanded === i }'
        :description="t(`ourTeam.${member}.description`)"
        @expand="x =>
        {
          lastExpanded = i;
          showBackdrop = x;
        }"
        :key="member"
        :linkedin="t(`ourTeam.${member}.linkedin`)"
        :name="t(`ourTeam.${member}.name`)"
        :picture="t(`ourTeam.${member}.picture`)"
        :picture-mobile="t(`ourTeam.${member}.pictureMobile`)"
        :position="t(`ourTeam.${member}.position`)"
        ref="memberRefs"
        v-for='(member, i) in t("ourTeam.team").split(",")'>
      </s-member>
    </div>
  </section>
</template>
