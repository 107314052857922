export default
{
  aboutUs:
  {
    description: `
      <h3><b>Facilitare la crescita e lo sviluppo</b> <br>delle imprese commerciali in Libia</h3>
      <br>
      <p style='font-weight: 300;'>Aiutiamo a facilitare la crescita e lo sviluppo delle imprese commerciali in Libia formando partenariati strategici con aziende nazionali e internazionali in settori industriali mirati, tra cui petrolio e gas, infrastrutture e servizi finanziari.</p>
      <br>
      <div class="border-severus-gold border-t-[1.5px]"></div>
      <br>
      <p style='font-weight: 300;'>Il focus delle attività di LHS è stato quello di fornire ai propri partner commerciali la conoscenza locale, la rete di contatti, il supporto operativo e il capitale iniziale per i coinvestimenti che saranno necessari per creare e far crescere imprese di successo in Libia.</p>`,
    reasons:
    {
      geographic:
      {
        title: "Copertura geografica",
        text: "<p>LHS è presente a Tripoli, Dubai e Londra, fornendo una copertura geografica delle regioni chiave per aiutare a facilitare gli investimenti diretti esteri in Libia.</p>",
      },
      governance:
      {
        title: "Governance",
        text: "<p>LHS raggiunge e fornisce standard di governance e controllo senza rivali in Libia. Attraverso la nostra rete di partner locali e internazionali, nonché il nostro team di revisori e professionisti finanziari con sede in Libia e Malta, siamo in grado di offrire tranquillità ai nostri partner di investimento internazionali.</p>",
      },
      infrastructure:
      {
        title: "Infrastrutture e investimenti",
        text: "<p>Siamo attivamente impegnati con numerosi partner internazionali leader nello sviluppo di grandi progetti infrastrutturali e investimenti strategici.</p>",
      },
      international:
      {
        title: "Partner internazionali",
        text: "<p>I partner internazionali saranno essenziali nel fornire competenze per aiutare il Paese e stiamo lavorando a stretto contatto con tali aziende per investire in Libia.</p>",
      },
      knowledge:
      {
        title: "Conoscenza locale",
        text: "<p>Forniamo ai partner commerciali le conoscenze locali, una rete di contatti, supporto operativo sul campo e capitale.</p>",
      },
    },
    subtitle: "<i>Alla <b>LH&nbsp;Severus</b> stiamo <br class='md:hidden'> lavorando per realizzare <br class='md:hidden'>il vero <b>potenziale della Libia.</b></i>",
    title: "Chi Siamo",
    titleImage: "about-us.it.svg",
  },
  contactUs:
  {
    email: "Indirizzo e-mail",
    generalInquiries: "Informazioni generali:",
    message: "Messaggio",
    name: "Nome",
    number: "Numero di contatto",
    send: "Inviare",
    subject: "Argomento",
    title: "Contattaci",
    titleImage: "contact-us.it.svg"
  },
  ourHistory:
  {
    description: "<p>LHS è stata fondata nel 2011 da Ahmed Ben Halim per sfruttare le opportunità di investimento emergenti che si sono sviluppate in Libia dopo la rivoluzione e che si stanno sviluppando ancora oggi.</p>",
    title: "La Nostra Storia",
    titleImage: "our-history.it.svg",
  },
  ourStrategy:
  {
    mission:
    {
      description: "<p>La missione di LHS è quella di sviluppare un gruppo industriale redditizio in Libia concentrando la nostra strategia su settori contingenti alla ripresa e alla crescita della Libia come petrolio e gas, produzione, infrastrutture e logistica.</p>",
      title: "Missione",
    },
    title: "La Nostra Strategia",
    titleImage: "our-strategy.it.svg",
    values:
    {
      description: "<p>Facilitare la crescita e lo sviluppo delle imprese commerciali in Libia formando partenariati strategici con aziende nazionali e internazionali in industrie e settori mirati.</p>",
      title: "Valori",
    },
    vision:
    {
      description: "<p>La nostra visione è quella di svolgere un ruolo positivo nella ricostruzione della Libia investendo e gestendo attività redditizie a beneficio dei loro stakeholder.</p>",
      title: "Visione",
    },
  },
  ourTeam:
  {
    beaton:
    {
      position: "Direttore finanziario",
      description: "<p>Signor Beaton è un dottore commercialista qualificato con un approccio commerciale focalizzato sul miglioramento dell'efficacia delle funzioni finanziarie per migliorare le prestazioni aziendali.</p><br><p>Ha una vasta gamma di esperienze in particolare in organizzazioni multinazionali complesse che operano in mercati di frontiera ed emergenti con oltre 20 anni di esperienza, principalmente nei settori dell'energia petrolifera e del gas, guidando il completamento di diversi grandi progetti infrastrutturali in ambienti difficili.</p>",
    },
    edwards:
    {
      position: "Partner d'investimento",
      description: "<p>Negli ultimi 25 anni Signor Edwards ha ricoperto diverse posizioni dirigenziali, sia come Managing Director che come Divisional Managing Director all'interno di aziende Fortune 500 e FTSE 250. Questi ruoli hanno avuto un focus specifico del settore nella generazione di energia, nella catena di approvvigionamento e nella logistica e nel settore dei servizi.</p><br><p>Signor Edwards ha vissuto e lavorato in tutta l'Africa e il Medio Oriente negli ultimi 15 anni. Ora si concentra sulla Libia e ricopre diversi ruoli di consulenza e non esecutivi aiutando le imprese internazionali a entrare nel mercato africano.</p>",
    },
    halim:
    {
      position: "Principale LHS",
      description: "<p>Signor Ahmed Ben Halim ha una miscela unica di esperienza come banchiere d'investimento con un ricco patrimonio libico.  Co-gestisce un portafoglio diversificato con oltre 7,5 miliardi di dollari di AUM.  Guida un team agile con una vasta esperienza nella realizzazione di investimenti complessi in ambienti di frontiera.</p>",
    },
    matarangasi:
    {
      position: "Direttore Commerciale",
      description: "<p>Signiorita Matarangasi ha conseguito un Master of Science (MSc) in Finanza e un Master of Arts (MA) in Global Trade Management. La sua area di competenza è l'approvvigionamento focalizzato sulla fornitura di leadership eccezionale e soluzioni strategiche per massimizzare i profitti riducendo al contempo le spese operative, negoziando condizioni favorevoli e semplificando i canali dei fornitori.</p><br><p>Ha più di 10 anni di esperienza nella supply chain e nel settore commerciale in aziende internazionali, occupandosi principalmente di gestione della supply chain per grandi progetti e investimenti.</p>",
    },
    rogers:
    {
      position: "Direttore del Tesoro",
      description: "<p>Signor Rogers ha conseguito diverse qualifiche in commercio con specializzazione in gestione aziendale e finanza. La sua competenza principale è la gestione quotidiana delle operazioni finanziarie, l'analisi dei costi e l'amministrazione generale. Contribuisce a fornire una solida struttura all'organizzazione aziendale e una sana amministrazione delle risorse di cassa.</p><br><p>Con una carriera finanziaria di oltre 15 anni, ha ricoperto una serie di ruoli senior all'interno delle divisioni finanziarie di società private e quotate in borsa. Il suo ruolo all'interno del team di gestione dei servizi condivisi è quello di fornire supporto nelle operazioni finanziarie, con particolare attenzione alla gestione della liquidità e alla rendicontazione finanziaria.</p>",
    },
    title: "Il Nostro Team",
  },
  portfolioCompanies:
  {
    bisa:
    {
      description: "<p>BISA, una compagnia specializzata nel trasporto marittimo e nella logistica, è orgogliosa di avere sede a Bengasi e di offrire i suoi servizi nei porti di Juliana e Tobruk. Con una squadra alimentato dalla passione, da una vasta conoscenza locale e da competenze, BISA è diventata rapidamente la compagnia preferita partner per i clienti che cercano servizi nella Libia orientale. Crediamo fermamente che nessun cliente sia troppo grande o troppo piccolo e siamo specializzati nel coltivare relazioni a lungo termine con clienti di agenzie multinazionali e internazionali. Come parte del gruppo LHS, BISA fornisce ai clienti con il vantaggio di un'offerta di servizi completa che soddisfa le loro esigenze in tutto il paese. Dall'inizio alla fine, BISA si dedica a fornire soluzioni logistiche e di spedizione eccezionali.</p>",
    },
    ghibli:
    {
      title: "Servizi Ghibli Manpower",
      description: "<p>Ghibli fornisce forza lavoro specializzata, energia mobile e servizi aziendali ai settori Oil & Gas, energia, costruzioni, comunicazione e logistica. Ghibli Manpower Services ha uffici a Tripoli, Bengasi e Misurata.</p>",
    },
    goToWebsite: "Sito web",
    intro: "<span class='font-semibold italic'><span class='text-severus-gold'>Dal 2015</span> LH&nbsp;Severus</span> <br class='md:hidden'>e le sue aziende partner <br class='md:hidden'>hanno <br class='max-md:hidden'>facilitato <span class='font-semibold italic'>la crescita <br class='md:hidden'>e lo sviluppo di quanto <br class='md:hidden'>segue:</span>",
    lcc:
    {
      title: "Compagnia libica del cemento (LCC)",
      description: "<p>LCC è il più grande produttore di cemento in Libia. L'azienda ha una capacità produttiva installata di 2,7 milioni di tonnellate in 3 stabilimenti situati a Bengasi, Hawari e Al Fatiah nella Libia orientale. LCC impiega oltre 2000 persone ed è il più grande datore di lavoro del settore privato nella Libia orientale.</p><br><p>LCC sta attualmente implementando un ampio piano di investimenti per modernizzare i propri impianti di produzione e aumentare la capacità produttiva.</p>",
    },
    location: "Posizione",
    lorasco:
    {
      description: "<p>LHS ha fondato LORASCO Ltd. nel 2016 per sviluppare un'azienda leader nei servizi di perforazione del settore privato al servizio delle compagnie petrolifere in Libia. LORASCO ha acquisito tre impianti di perforazione onshore ed è in procinto di sviluppare la sua base di clienti in Libia.</p>",
    },
    mstc:
    {
      description: "<p>MSTC, una compagnia specializzata nel trasporto marittimo e nella logistica, è orgogliosa di avere sede a Tripoli e di offrire i suoi servizi nei porti di Tripoli, Misurata e Khoms. Crediamo fermamente che nessun cliente sia troppo grande o troppo piccolo e siamo specializzati nel coltivare rapporti a lungo termine con clienti di agenzie multinazionali e internazionali. Come parte del gruppo LHS, MSTC offre ai clienti il ​​vantaggio di un'offerta di servizi completa che soddisfa le loro esigenze in tutto il paese. Dall'inizio alla fine, MSTC è dedicata a offrendo soluzioni di spedizione e logistica eccezionali.</p>",
    },
    titleImage: "portfolio-companies.it.svg",
  },
  siteFooter:
  {
    address:
    {
      benghazi:
      {
        name: "Bengazi",
      },
    },
  },
  siteHeader:
  {
    links:
    {
      about: "Chi siamo",
      aboutLink: "chi-siamo",
      contact: "Contattaci",
      contactLink: "contattaci",
      portfolio: "Aziende di portafoglio",
      portfolioLink: "aziende-di-portafoglio",
      strategy: "La nostra strategia",
      strategyLink: "la-nostra-strategia",
      team: "Il nostro team",
      teamLink: "il-nostro-team",
    }
  },
  videoCarousel:
  {
    banner: "<i><span class='bg-severus-gold font-black py-[2px] px-[10px]'>LH&nbsp;SEVERUS</span> <span class='font-semibold'>PUNTA AD ESSERE IL PARTNER D'INVESTIMENTO</span> <br>per guidare aziende internazionali che desiderano servire il mercato libico</i>",
    desktop:
    {
      bisa:
      {
        image: "bisa.it.svg",
      },
      ghibli:
      {
        image: "ghibli.it.svg",
      },
      lcc:
      {
        button:
        {
          right: "16.5%",
        },
        image: "lcc.it.svg",
      },
      lorasco:
      {
        button:
        {
          right: "18.25%",
          top: "61%",
        },
        image: "lorasco.it.svg",
      },
      mstc:
      {
        image: "mstc.it.svg",
      },
    },
    mobile:
    {
      bisa:
      {
        image: "bisa.m.it.svg",
      },
      ghibli:
      {
        image: "ghibli.m.it.svg",
      },
      lcc:
      {
        image: "lcc.m.it.svg",
      },
      lhs:
      {
        video: "lhs.m.it.mp4",
      },
      lorasco:
      {
        image: "lorasco.m.it.svg",
      },
      mstc:
      {
        image: "mstc.m.it.svg",
      },
    },
    readMore: "Per saperne di più",
  },
  whyLibya:
  {
    reasons:
    {
      broadAndDeep:
      {
        description: "<p>Le opportunità in Libia sono ampie e profonde poiché gli investimenti nel paese sono stati trascurati per decenni in numerosi settori industriali chiave.</p><br><p>La Libia ha la ricchezza di risorse naturali per il suo futuro sviluppo e ricostruzione dopo la recente rivoluzione.</p><br><p>La Libia richiede centinaia di miliardi di dollari di investimenti nel prossimo decennio e ha bisogno delle capacità e della tecnologia delle società straniere per realizzare i progressi sociali ed economici richiesti dalla sua gente.</p>",
        title: "Ampio e <br>profondo",
      },
      lowCompetition:
      {
        description: "<p>In Libia c’è poca o nessuna classe mercantile a causa della natura totalitaria del regime precedente.</p><br><p>Ciò significa che ci sono pochi concorrenti a livello locale con le capacità per cogliere le opportunità che ora appaiono in Libia.</p><br><p>Le leggi nazionali richiederanno che la maggior parte delle aziende (Tutte le società straniere DEVONO…) lavori con un partner locale e le scelte a loro disposizione sono scarse.</p>",
        title: "Bassa <br>concorrenza",
      },
      oilCountry:
      {
        description: "<p>La Libia dipende dal settore petrolifero per il 95% dei proventi delle esportazioni, per il 25% del PIL e per l’80% delle entrate pubbliche, ma ad oggi, solo il 25% del suo territorio è stato esplorato.</p><br><p>Il suo settore petrolifero è sottosviluppato poiché la produzione è stata in declino per gran parte degli ultimi 40 anni e non sono state impiegate tecnologie di produzione avanzate.</p><br><p>Il settore petrolchimico non è così sviluppato come i mercati del petrolio e del gas del paese.</p>",
        title: "Paese <br>petrolifero",
      },
      strategicLocation:
      {
        description: "<p>La posizione della Libia, con oltre 2.000 km di coste rivolte verso l'Europa, è strategicamente significativa. Ha accesso a mercati significativi e in crescita senza sbocco sul mare nell’Africa subsahariana e si trova a soli 280 km dall’Unione Europea. Ciò rende la Libia il naturale hub di investimenti che collega il mondo sviluppato e quello in via di sviluppo</p>",
        title: "Posizione <br>strategica",
      },
    },
    title: "Perché Libia?",
    titleImage: "why-libya.it.svg",
  },
};
