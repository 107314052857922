<script lang="ts" setup>
import ar from "@/i18n/ar";
import { useLocale } from "vuetify";

const { t } = useLocale();
</script>

<template>
  <section>
    <s-header
      background="/images/general/blue-banner.webp"
      :image='t("portfolioCompanies.titleImage")'
      :title='t("portfolioCompanies.title")'>
    </s-header>
    <!-- banner -->
    <div class="flex justify-center relative">
      <img
        class="h-40 mt-4 absolute left-[17.5%] max-xl:hidden -translate-x-1/2"
        src="/images/graphics/libya-lines.svg">
      <div class="py-16 sm:py-10 text-2xl text-severus-blue font-light"
        v-html='t("portfolioCompanies.intro")'>
      </div>
    </div>
    <!-- companies -->
    <div
      class='
        flex flex-col
        bg-[url("/images/general/dot-texture.webp")] bg-repeat bg-[size:30%] bg-fixed'>
      <s-company
        v-for='c in t("portfolioCompanies.companies").split(",")'
        :description="t(`portfolioCompanies.${c}.description`)"
        :shortdescription="t(`portfolioCompanies.${c}.shortdescription`)"
        :id="c"
        :key="c"
        :logo="t(`portfolioCompanies.${c}.logo`)"
        :gps-lat="parseFloat(t(`portfolioCompanies.${c}.gpsLat`))"
        :gps-lng="parseFloat(t(`portfolioCompanies.${c}.gpsLng`))"
        :title="t(`portfolioCompanies.${c}.title`)"
        :marker_title_ar="t(`portfolioCompanies.${c}.marker_title_ar`)"
        :marker_title_en="t(`portfolioCompanies.${c}.marker_title_en`)"
        :website="t(`portfolioCompanies.${c}.website`)">
      </s-company>
    </div>
  </section>
</template>
