import "./main.css";
import { createApp } from "vue";
import App from "./App.vue";

// Plugins:
import router from "./plugins/router";
import pinia from "./plugins/pinia";
import vuetify from "./plugins/vuetify";

createApp(App)
  .use(vuetify)
  .use(pinia)
  .use(router)
  .mount("#app")
