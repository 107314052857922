import "@mdi/font/css/materialdesignicons.css";
import { severusDark, severusLight } from "./themes";
import { createVuetify } from "vuetify";
import { mdi } from "vuetify/iconsets/mdi";
import "vuetify/styles";

// Adapter: vue-i18n.
import { useI18n } from "vue-i18n";
import { createVueI18nAdapter } from "vuetify/locale/adapters/vue-i18n";
import i18n from "@/plugins/i18n";

const vuetify = createVuetify(
{
  display:
  {
    mobileBreakpoint: "md",
  },
  icons:
  {
    sets: { mdi },
  },
  locale:
  {
    adapter: createVueI18nAdapter({ i18n, useI18n }),
  },
  theme:
  {
    defaultTheme: "severusLight",
    themes:
    {
      severusDark,
      severusLight,
    },
  },
});

export default vuetify;
