export default
{
  aboutUs:
  {
    description: `
      <h3><i><b>Facilitating the growth <br class='md:hidden'>and development</b> <br>of commercial <br class='md:hidden'>enterprise in Libya</i></h3>
      <br>
      <p style='font-weight: 300;'>We help facilitate the growth and development of commercial enterprise in Libya by forming strategic partnerships with international and domestic companies in targeted industry sectors, including oil & gas, infrastructure and financial services.</p>
      <br>
      <div class="border-severus-gold border-t-[1.5px]"></div>
      <br>
      <p style='font-weight: 300;'>The focus of activities for LHS has been in providing its commercial partners with the local knowledge, network of contacts, operational support and seed capital for co-investments that will be required to create and grow successful enterprises in Libya.</p>`,
    reasons:
    {
      list: "knowledge,infrastructure,international,geographic,governance",
      geographic:
      {
        title: "Geographic coverage",
        text: "<p><span class='about-accordion-description-bold'>LHS</span> has a presence in <span class='about-accordion-description-bold'>Tripoli, Dubai</span> and <span class='about-accordion-description-bold'>London</span>, providing a geographic coverage of key regions to help facilitate foreign direct investment into Libya.</p>",
        img: "geographic.webp",
        imgMobile: "geographic.m.webp",
      },
      governance:
      {
        title: "Governance",
        text: "<p><span class='about-accordion-description-bold'>LHS</span> achieves and provides unrivalled <span class='about-accordion-description-bold'>standards of governance and control in Libya.</span>  Through our network of local and international partners as well as our own team of audit and financial professionals based in Libya and Malta we are able to provide peace of mind to our international investment partners.</p>",
        img: "governance.webp",
        imgMobile: "governance.m.webp",
      },
      infrastructure:
      {
        title: "Infrastructure and investments",
        text: "<p>We are actively engaged with numerous leading international partners to develop <span class='about-accordion-description-bold'>large infrastructure projects and strategic investments.</span></p>",
        img: "infrastructure.webp",
        imgMobile: "infrastructure.m.webp",
      },
      international:
      {
        title: "International partners",
        text: "<p><span class='about-accordion-description-bold'>International partners</span> will be essential in <span class='about-accordion-description-bold'>providing expertise</span> to help the country and we are working closely with such companies to invest in Libya.</p>",
        img: "international.webp",
        imgMobile: "international.m.webp",
      },
      knowledge:
      {
        title: "Local knowledge",
        text: "<p>We provide commercial partners with <span class='about-accordion-description-bold'>local knowledge, a network of contacts, on-the-ground operational support</span> and capital.</p>",
        img: "knowledge.webp",
        imgMobile: "knowledge.m.webp",
      },
    },
    subtitle: "<i>At <b>LH&nbsp;Severus</b> we are <br class='md:hidden'>working to realize <br class='md:hidden'><b>Libya's true potential</b></i>",
    title: "About Us",
    titleImage: "about-us.en.svg",
  },
  contactUs:
  {
    email: "Email address",
    generalInquiries: "General enquiries:",
    generalInquiriesAddress: "info&#64;lhseverus.com",
    message: "Message",
    name: "Name",
    number: "Contact number",
    send: "Send",
    subject: "Subject",
    title: "Contact Us",
    titleImage: "contact-us.en.svg",
  },
  downloads:
  {
    title: "Downloads",
  },
  news:
  {
    title: "News",
  },
  ourHistory:
  {
    description: "<p>LHS was founded in 2011 by Ahmed Ben Halim to take advantage of the emerging investment opportunities that developed in Libya following the revolution and that are still developing today.</p>",
    title: "Our History",
    titleImage: "our-history.en.svg",
  },
  ourStrategy:
  {
    mission:
    {
      description: "<p>LHS’s mission is to develop a profitable industrial group in Libya by focusing our strategy on sectors that are contingent to the recovery and growth of Libya such as oil & gas, manufacturing, infrastructure and Logistics.</p>",
      title: "Mission",
    },
    title: "Our Strategy",
    titleImage: "our-strategy.en.svg",
    values:
    {
      description: "<p>To facilitate the growth and development of commercial enterprise in Libya by forming strategic partnerships with international and domestic companies in targeted industries and sectors. </p>",
      title: "Values",
    },
    vision:
    {
      description: "<p>It is our vision to play a positive part in the re-construction of Libya by investing in and running profitable business for the benefit of their stake holders.</p>",
      title: "Vision",
    },
  },
  ourTeam:
  {
    beaton:
    {
      name: "Graham Beaton",
      position: "Chief Finance Officer",
      description: "<p>Mr Beaton is a Qualified Chartered Accountant with a commercially focused approach to improving the effectiveness of finance functions to drive improved business performance.</p><br><p>He has a broad range of experience particularly in complex multinational organisations operating in frontier and emerging markets with over 20 years experience, mainly in the oil & gas energy sectors, leading the completion of several large infrastructure projects in challenging environments.</p>",
      picture: "beaton.webp",
      pictureMobile: "beaton.m.webp",
      linkedin: "https://www.linkedin.com/in/graham-beaton-ma-hons-ca-417326125/",
    },
    edwards:
    {
      name: "David Edwards",
      position: "Investment Partner",
      description: "<p>Mr. Edwards has held a number of senior positions over the past 25 years, both Managing Director and Divisional Managing Director roles within Fortune 500 and FTSE 250 Companies. These roles have had specific industry focus in power generation, supply chain and logistics and the service sector.</p><br><p>Mr Edwards has lived and worked throughout Africa and the Middle East over the past 15 years. He now focuses on Libya as well as holding several advisory and non-exec roles helping international businesses enter the Africa market place.</p>",
      picture: "edwards.webp",
      pictureMobile: "edwards.m.webp",
      linkedin: "",
    },
    halim:
    {
      name: "Ahmed Mostapha Ben Halim",
      position: "LHS principal",
      description: "<p>Ahmed Ben Halim has a unique blend of experience as an Investment Banker with a rich Libyan heritage.  He co-manages a diverse portfolio with over $7.5 Billion of AUM.  He leads an agile team with a range of experience delivering complex investments in frontier environments.</p>",
      picture: "halim.webp",
      pictureMobile: "halim.m.webp",
      linkedin: "",
    },
    matarangasi:
    {
      name: "Nidia Matarangasi",
      position: "Commercial Manager",
      description: "<p>Ms Matarangasi holds a Master of Science (MSc) in Finance and Master of Arts (MA) in Global Trade Management. Her area of expertise is procurement focused on providing exceptional leadership and strategic solutions to maximize profit while decreasing operational expenses, negotiate favourable terms and streamlining supplier channels.</p><br><p>She has more than 10 years experience  in supply chain and commercial field in international companies, mostly dealing with supply chain management for large projects and investments.</p>",
      picture: "matarangasi.webp",
      pictureMobile: "matarangasi.m.webp",
      linkedin: "https://www.linkedin.com/in/nidiamatarangasi/",
    },
    rogers:
    {
      name: "Neil Rogers",
      position: "Treasury Manager",
      description: "<p>Mr Rogers holds multiple qualifications in commerce with specialisation in Business Management and Finance. His core competence is in the day-to-day running of financial operations, cost analysis, and general administration. He contributes in providing a solid structure to business organisation, and sound administration of cash resources.</p><br><p>With a finance career spanning over 15 years, he has occupied a number of senior roles within the financial divisions of both private and publicly traded companies. His role within the Shared Service Management Team is to provide support in financial operations, with particular focus on cash management, and financial reporting.</p>",
      picture: "rogers.webp",
      pictureMobile: "rogers.m.webp",
      linkedin: "https://mt.linkedin.com/in/neil-rogers-30614873",
    },
    team: "halim,edwards,beaton,rogers,matarangasi",
    title: "Our Team",
    titleImage: "our-team.en.svg",
  },
  portfolioCompanies:
  {
    bisa:
    {
      title: "BISA",
      marker_title_en: "BISA",
      marker_title_ar: "شركة بنغازي الدولية للتوكيلات الملاحية",
      logo: "bisa.webp",
      description: "<p>BISA, a specialist maritime shipping and logistics company, is proud to be headquartered in Benghazi and offer its services in the Ports of Juliana and Tobruk. With a team fuelled by passion, extensive local knowledge, and expertise, BISA has quickly become the preferred partner for clients seeking services in Eastern Libya. We firmly believe that no customer is too large or to small, and we specialize in cultivating long-term relationships with both multinational and international agency clients. As part of the LHS Group, BISA provides customers with the advantage of a comprehensive service offering that caters to their needs across the entire country. From start to finish, BISA is dedicated to delivering exceptional shipping and logistics solutions.<p>",
      shortdescription: "Maritime Shipping and Logistics Company",
      website: "https://www.bisa-shipping.com/",
      gpsLat: "32.0805953",
      gpsLng: "20.0635125",
    },
    companies: "lcc,lorasco,ghibli,mstc,bisa",
    ghibli:
    {
      title: "Ghibli Manpower Services",
      marker_title_en: "Ghibli Limited",
      marker_title_ar: "قبلي لخدمات القوى العاملة",
      logo: "ghibli.webp",
      description: "<p>Ghibli provides specialist manpower, mobile power and business services to the Oil & Gas, power, construction, communication and logistics sectors. Ghibli Manpower Services has offices in Tripoli, Benghazi and Misurata.</p>",
      shortdescription: "Manpower Supply",
      website: "https://www.ghiblilimited.com",
      gpsLat: "32.0701667",
      gpsLng: "20.0661667",
    },
    goToWebsite: "Go to website",
    intro: "<span class='font-semibold italic'><span class='text-severus-gold'>Since 2015</span> LH&nbsp;Severus</span> <br class='md:hidden'>and its partner companies <br class='md:hidden'>have <br class='max-md:hidden'>facilitated the <span class='font-semibold italic'>growth <br class='md:hidden'>and development</span> of <br class='md:hidden'>the following:",
    lcc:
    {
      title: "Libyan Cement Company",
      marker_title_en: "Libyan Cement Company",
      marker_title_ar: "شركة الاسمنت الليبية",
      logo: "lcc.webp",
      description: "<p>LCC is the largest producer of cement in Libya. The company has an installed production capacity of 2.7M tons across 3 plants located in Benghazi, Hawari, and Al Fatiah in Eastern Libya. LCC employs over 2000 people making it the largest private sector employer in Eastern Libya.</p><p>LCC is currently implementing an extensive investment plan to modernise its production facilities and increase production capacity.</p>",
      shortdescription: "Cement Producer",
      website: "https://www.lcc.ly/",
      gpsLat: "32.0064167",
      gpsLng: "20.1308056",
    },
    location: "Location",
    lorasco:
    {
      title: "LORASCO",
      marker_title_en: "LORASCO",
      marker_title_ar: "لوراسكو",
      logo: "lorasco.webp",
      description: "<p>LHS founded LORASCO Ltd. in 2016 to develop a leading private sector drilling services business servicing oil companies in Libya. LORASCO acquired three onshore drilling rigs and is in the process of developing its client base in Libya.</p>",
      shortdescription: "Oil services business",
      website: "https://www.lorasco.com",
      gpsLat: "32.87526",
      gpsLng: "13.125954",
    },
    mstc:
    {
      title: "MSTC",
      marker_title_en: "MSTC",
      marker_title_ar: "شركة مسارات المتوسط للشحن والتوكيلات الملاحية",
      logo: "mstc.webp?v1",
      description: "<p>MSTC, a specialist maritime shipping and logistics company, is proud to be headquartered in Tripoli and offer its services in the Ports of Tripoli, Misurata and Khoms. We firmly believe that no customer is too large or too small, and we specialize in cultivating long-term relationships with both multinational and international agency clients. As part of the LHS Group, MSTC provides customers with the advantage of a comprehensive service offering that caters to their needs across the entire country. From start to finish, MSTC is dedicated to delivering exceptional shipping and logistics solutions.</p>",
      shortdescription: "Maritime Shipping and Logistics Company",
      website: "https://mstc.ly/",
      gpsLat: "32.8873611",
      gpsLng: "13.1657222",
    },
    readLess: "Show less",
    readMore: "Read more",
    title: "Portfolio Companies",
    titleImage: "portfolio-companies.en.svg",
  },
  siteFooter:
  {
    address:
    {      
      list: "malta",
      malta:
      {
        name: "Malta",
        number: "Tel: +356 2122 4361",
        postal: "189, Suite 11, Marina Suites, <br>Marina Street, Pieta’ Malta",
      },    
    },
  },
  siteHeader:
  {
    links:
    {
      about: "About Us",
      aboutLink: "about-us",
      contact: "Contact",
      contactLink: "contact-us",
      downloads: "Downloads",
      downloadsLink: "downloads",
      news: "News",
      newsLink: "news",
      portfolio: "Portfolio",
      portfolioLink: "portfolio-companies",
      strategy: "Strategy",
      strategyLink: "our-strategy",
      team: "The Team",
      teamLink: "our-team",
    },
  },
  videoCarousel:
  {
    banner: "<i><h1><span class='bg-severus-gold font-black py-[2px] px-[10px]'>LH&nbsp;SEVERUS</span> <span class='font-semibold'>AIMS TO BE THE INVESTMENT PARTNER</span> <br>for leading international companies wishing to serve the Libyan market</i></h1>",
    desktop:
    {
      list: "bisa,lorasco,ghibli,lcc,mstc",
      bisa:
      {
        button:
        {
          color: "#fff",
          left: "25.5%",
          right: "auto",
          top: "76%",
          variant: "outlined",
        },
        image: "bisa.en.svg",
        video: "bisa.mp4",
      },
      ghibli:
      {
        button:
        {
          color: "#fff",
          left: "auto",
          right: "15.25%",
          top: "75.5%",
          variant: "flat",
        },
        image: "ghibli.en.svg",
        video: "ghibli.mp4",
      },
      lcc:
      {
        button:
        {
          color: "#fff",
          left: "auto",
          right: "16%",
          top: "79%",
          variant: "outlined",
        },
        image: "lcc.en.svg",
        video: "lcc.mp4",
      },
      lorasco:
      {
        button:
        {
          color: "#fff",
          left: "auto",
          right: "18%",
          top: "58%",
          variant: "flat",
        },
        image: "lorasco.en.svg",
        video: "lorasco.mp4",
      },
      mstc:
      {
        button:
        {
          color: "#fff",
          left: "auto",
          right: "16.75%",
          top: "67%",
          variant: "flat",
        },
        image: "mstc.en.svg",
        video: "mstc.mp4",
      },
    },
    mobile:
    {
      list: "lhs,bisa,lorasco,ghibli,lcc,mstc",
      bisa:
      {
        button:
        {
          color: "#fff",
          left: "16.5%",
          right: "auto",
          top: "84%",
          variant: "outlined",
        },
        image: "bisa.m.en.svg",
        video: "bisa.m.mp4",
      },
      ghibli:
      {
        button:
        {
          color: "#fff",
          left: "auto",
          right: "7%",
          top: "89%",
          variant: "flat",
        },
        image: "ghibli.m.en.svg",
        video: "ghibli.m.mp4",
      },
      lcc:
      {
        button:
        {
          color: "#e81f27",
          left: "11%",
          right: "auto",
          top: "85%",
          variant: "flat",
        },
        image: "lcc.m.en.svg",
        video: "lcc.m.mp4",
      },
      lhs:
      {
        button:
        {
          color: "#00000000",
          left: "1000%",
          right: "0",
          top: "100%",
          variant: "text",
        },
        image: "blank.svg",
        video: "lhs.m.en.mp4",
      },
      lorasco:
      {
        button:
        {
          color: "#fff",
          left: "34%",
          right: "34%",
          top: "87%",
          variant: "flat",
        },
        image: "lorasco.m.en.svg",
        video: "lorasco.m.mp4",
      },
      mstc:
      {
        button:
        {
          color: "#fff",
          left: "auto",
          right: "11.5%",
          top: "88%",
          variant: "flat",
        },
        image: "mstc.m.en.svg",
        video: "mstc.m.mp4",
      },
    },
    readMore: "Read more",
  },
  whyLibya:
  {
    reasons:
    {
      broadAndDeep:
      {
        description: "The opportunity in Libya is both broad and deep as investment in the country has been neglected for decades across a number of key industrial sectors. Libya has the natural resource wealth to its future development and reconstruction following the recent revolution. Libya requires hundreds of billions of dollars of investment over the coming decade and needs the capabilities and technology of foreign companies to deliver the social and economic advances its people demand.",
        title: "Broad <br>& Deep",
      },
      lowCompetition:
      {
        description: "There is little to no merchant class domestically in Libya due to the totalitarian nature of the previous regime. This means there are few competitors locally with the capabilities to capture the opportunities now appearing in Libya. Domestic laws will require that most companies work with a local partner and the choices available to them are scarce.",
        title: "Low <br>competition",
      },
      oilCountry:
      {
        description: "<p>Libya depends on the oil sector for 95% of export earnings, 25% of its GDP and 80% of government revenues but only 25% of its territory has been explored to date.</p><br><p>Its oil sector is underdeveloped as output has been in decline for most of the last 40 years and advanced production technologies have not been employed.</p><br><p>The petrochemicals sector is not as well developed as the country's oil and gas markets.</p>",
        title: "Oil <br>country",
      },
      strategicLocation:
      {
        description: "Libya's location with over 2000km of coastline facing Europe is strategically significant. It has access to significant and growing landlocked markets in sub Saharan Africa as well as only being 280km from the European Union. This makes Libya the natural investment hub linking the developed and developing world",
        title: "Strategic <br>location",
      },
    },
    title: "Why Libya?",
    titleImage: "why-libya.en.svg",
  },
  "$vuetify.carousel.ariaLabel.delimiter": "",
};
