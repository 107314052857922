<script lang="ts" setup>
import { RouterView } from "vue-router";
import { useLocale } from "vuetify";

const { current } = useLocale();
</script>

<template>
  <v-app :class="`lang-${current}`">
    <SiteHeader></SiteHeader>
    <RouterView />
  </v-app>
</template>
