<script lang="ts" setup>
import { ref } from "vue";
import { GoogleMap, Marker as GoogleMapMarker, InfoWindow as GoogleMapInfoWindow } from "vue3-google-map";
import { useDisplay, useLocale } from "vuetify";

defineProps(
  {
    description: String,
    shortdescription: String,
    gpsLat: Number,
    gpsLng: Number,
    id: String,
    logo: String,
    title: String,
    marker_title_ar: String,
    marker_title_en: String,
    website: String,
  });

const { mobile } = useDisplay();
const { current, t } = useLocale();

const showMap = ref(false);
const showMarkerInfo = ref(true);
const showDescription = ref(false);

const wipShow = ref(false);

const stripPTags = (htmlString: string) => {
  return htmlString.replace(/<\/?p>/g, '');
};
</script>

<template>
  <div class="
      rounded-sm shadow-md bg-white my-5
      mx-[5%] xl:max-w-[60%] xl:w-full xl:mx-auto
      flex flex-col md:flex-row justify-between items-center" :class='{ mobile, "md:flex-row-reverse": current === "ar" }' :id="id">
    <div class="min-w-[280px] px-10 py-14">
      <v-img :src="`/images/portfolio-companies/${logo}`"></v-img>
    </div>
    <div v-if="!showMap" class='
        py-10 md:py-20 px-10 bg-contain max-md:w-full
        bg-[url("/images/portfolio-companies/shadow-h.png")]
        md:bg-[url("/images/portfolio-companies/shadow-v.png")]'>
      <h3 class="text-severus-blue text-xl max-md:text-center">
        {{ title }}
      </h3>
      <s-expand>
        <div v-show="!mobile || showDescription">
          <div class="p-4 md:px-0" v-html="description">
          </div>
        </div>
      </s-expand>
      <!-- buttons -->
      <div class="
        flex flex-wrap flex-col md:flex-row
        pt-4 max-md:mx-auto w-[70%]
        [&>*]:!mr-0 gap-2 md:[&>*]:px-10">
        <v-btn v-if="mobile" class="!rounded-sm" @click="showDescription = !showDescription" color="black" variant="flat">
          {{ showDescription
            ? t("portfolioCompanies.readLess")
            : t("portfolioCompanies.readMore") }}
        </v-btn>
        <v-btn class="
            swipe !rounded-sm text-white px-6
            !bg-gradient-to-r from-severus-blue-light to-severus-blue
            before:bg-black before:rounded-sm" :href="website" rel="noopener noreferrer" target="_blank" variant="flat">
          {{ t("portfolioCompanies.goToWebsite") }}
        </v-btn>
        <v-btn append-icon="mdi-chevron-right" class="
            swipe !rounded-sm px-6
            before:bg-black before:rounded-sm
            hover:text-white hover:border-black" @click="showMap = !showMap" prepend-icon="mdi-map-marker" variant="outlined">
          <span>{{ t("portfolioCompanies.location") }}</span>
        </v-btn>
      </div>
    </div>
    <google-map v-if="showMap" api-key="AIzaSyAs-KKpt2Ds6c73HxXhjoyoxdrcEFxNe4Q" class="googlemapMarker relative h-[500px] w-full" :center="{ lat: gpsLat, lng: gpsLng }" :zoom="15">
      <google-map-marker @click="showMarkerInfo = !showMarkerInfo" :options="{ position: { lat: gpsLat, lng: gpsLng } }" />

      <google-map-info-window v-if="showMarkerInfo" :options="{ position: { lat: gpsLat, lng: gpsLng }, pixelOffset: { width: 0, height: -40 } }">
        <!-- Content inside the info window -->
        <div>
          <p style="margin-bottom: 5px; font-size: 12px;">{{ marker_title_ar }}</p>          
          <p>{{ marker_title_en }}</p>          
          <p class="p-4 md:px-0 googleInfoWindowDescription">{{ shortdescription }}</p>
          <!-- <v-btn class="
            swipe !rounded-sm text-white px-6
            !bg-gradient-to-r from-severus-blue-light to-severus-blue
            before:bg-black before:rounded-sm" :href="website" rel="noopener noreferrer" target="_blank" variant="flat">
            {{ t("portfolioCompanies.goToWebsite") }}</v-btn> -->
        </div>
      </google-map-info-window>

      <v-btn class="bottom-6 left-2.5 !absolute !rounded-sm" @click="showMap = !showMap" color="black">
        Close map
      </v-btn>
    </google-map>
  </div>
  <v-dialog v-model="wipShow">
    <div class="construction">
      <h3>Site currently under construction.</h3>
      <v-btn class="mt-8" @click="wipShow = false" color="black">
        Dismiss
      </v-btn>
    </div>
  </v-dialog>
</template>

<style scoped>
/* Fix relative bug. */
:deep() {

  .v-btn__overlay,
  .v-btn__underlay {
    display: none;
  }
}
</style>
