<script lang="ts" setup>
import { useLocale } from "vuetify"; // TODO: OBSOLETE.

const { current, t } = useLocale();
</script>

<template>
  <section>
    <s-header
      background="/images/general/blue-banner.webp"
      :image='t("ourStrategy.titleImage")'
      :title='t("ourStrategy.title")'>
    </s-header>
    <div class="md:flex md:flex-row-reverse">
      <div
        class='
          w-full md:w-1/2 max-md:aspect-[2/1]
          bg-center bg-cover
          bg-[url("/images/strategy/mission.m.webp")]
          md:bg-[url("/images/strategy/mission.webp")]'>
      </div>
      <div class="bg-[#dbdbdb] w-full md:w-1/2">
        <h3 class="text-severus-blue border-severus-blue">
          {{ t("ourStrategy.mission.title") }}
        </h3>
        <div
          class="max-md:p-[15%] p-[20%] pt-[5%] leading-relaxed"
          v-html='t("ourStrategy.mission.description")'>
        </div>
      </div>
    </div>
    <div class="md:flex">
      <div
        class='
          w-full md:w-1/2 max-md:aspect-[2/1]
          bg-center bg-cover
          bg-[url("/images/strategy/vision.m.webp")]
          md:bg-[url("/images/strategy/vision.webp")]'
          style="z-index: 1;">
      </div>
      <div class="relative bg-severus-blue text-white w-full md:w-1/2 overflow-hidden">
        <s-parallax
          class="!absolute bottom-0 top-0 max-md:!hidden w-full"
          :class='{
            "-left-1/2": current !== "ar",
            "-right-1/2": current === "ar" }'
          overflow
          src="/images/graphics/rose-gold.svg">
        </s-parallax>
        <h3 class="relative">{{ t("ourStrategy.vision.title") }}</h3>
        <div
          class="max-md:p-[15%] p-[20%] pt-[5%] leading-relaxed relative"
          v-html='t("ourStrategy.vision.description")'>
        </div>
      </div>
    </div>
    <div class="md:flex md:flex-row-reverse">
      <div
        class='
          w-full md:w-1/2 max-md:aspect-[2/1]
          bg-center bg-cover
          bg-[url("/images/strategy/values.m.webp")]
          md:bg-[url("/images/strategy/values.webp")]'>
      </div>
      <div class="bg-[#dbdbdb] w-full md:w-1/2">
        <h3 class="text-severus-blue border-severus-blue">
          {{ t("ourStrategy.values.title") }}
        </h3>
        <div
          class="max-md:p-[15%] p-[20%] pt-[5%] leading-relaxed"
          v-html='t("ourStrategy.values.description")'>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
h3
{
  @apply
    text-xl font-semibold text-center
    max-md:text-white max-md:bg-black/30
    md:inline-block
    md:px-8 py-2.5 md:py-1
    md:mx-[20%] md:mt-[20%]
    uppercase
    md:border-[1px] md:rounded-sm;
}
</style>
