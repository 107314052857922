<script lang="ts" setup>
import { ref } from "vue";
import { useDisplay, useLocale } from "vuetify";

const { mobile } = useDisplay();
const { t } = useLocale();

const selection = ref(-1);
</script>

<template>
  <div>
    <v-expansion-panels
      v-model="selection"
      variant="accordion">
      <v-expansion-panel
        v-for='(reason) in t("aboutUs.reasons.list").split(",")'
        class="mb-2"
        :key="reason">
        <v-expansion-panel-title
          class="panel-title bg-cover text-white px-2.5 max-md:!py-14 md:!py-8 bg-center"
          :style='`background-image: url("/images/about/${mobile
            ? t(`aboutUs.reasons.${reason}.imgMobile`)
            : t(`aboutUs.reasons.${reason}.img`)}");`'>
          <span class="w-full font-semibold text-2xl text-white italic text-center">
            {{ t(`aboutUs.reasons.${reason}.title`) }}
          </span>
          <template v-slot:actions></template>
        </v-expansion-panel-title>
        <v-expansion-panel-text
          class="relative bg-severus-blue max-md:text-white md:bg-white mdtext-black">
          <div
            class="text-center mt-8 mx-8 mb-4"
            v-html="t(`aboutUs.reasons.${reason}.text`)"></div>
          <v-btn
            class="w-full"
            @click="selection = -1"
            variant="text">
            <v-icon icon="mdi-chevron-up" size="x-large"></v-icon>
          </v-btn>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<style scoped>
.panel-title
{
  &:before
  {
    @apply bg-severus-blue h-full w-2 absolute bottom-0 left-0;

    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
    content: "";
    transition: width 0.5s;
  }
}

.panel-text:before
{
  @apply bg-severus-blue absolute left-0 right-0 top-0 h-1;
  content: "";
}
</style>
