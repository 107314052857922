export default
{
  aboutUs:
  {
    description: `
      <h3><b>تسهيل نمو وتطور</b> <br>المؤسسات التجارية في ليبيا.</h3>
      <br>
      <p style='font-weight: 300;'>نحن نساعد في تسهيل نمو وتطور المشاريع التجارية في ليبيا من خلال تكوين شراكات استراتيجية مع الشركات الدولية والمحلية في قطاعات الصناعة المستهدفة، بما في ذلك النفط والغاز والبنية التحتية والخدمات المالية.</p>
      <br>
      <div class="border-severus-gold border-t-[1.5px]"></div>
      <br>
      <p style='font-weight: 300;'>تركز أنشطة شركة سيفيروس ليبيا القابضة على تزويد شركائها بالمعرفة المحلية وشبكة اتصالات ودعم تشغيلي ورأس مال وهي الشركة الأولي للاستثمارات المشتركة والتي ستكون مطلوبة لإنشاء وتنمية مشاريع ناجحة في ليبيا.</p>`,
    reasons:
    {
      geographic:
      {
        title: "التغطية الجغرافية",
        text: "<p>تتواجد شركة شركة سيفيروس ليبيا القابضة في طرابلس ودبي ولندن، حيث توفر تغطية جغرافية للمناطق الرئيسية للمساعدة في تسهيل الاستثمار الأجنبي المباشر في ليبيا.</p>",
      },
      governance:
      {
        title: "الحوكمة",
        text: "<p>تحقق شركة شركة سيفيروس ليبيا القابضة وتقدم معايير لا مثيل لها في التطوير والإدارة في ليبيا. من خلال شبكتنا من الشركاء المحليين والدوليين بالإضافة إلى فريقنا الخاص من الماليين ذوي خبرة و دقة في العمل، الموجودين في ليبيا ومالطا، نحن قادرون على توفير الراحة لشركائنا الاستثماريين الدوليين.</p>",
      },
      infrastructure:
      {
        title: "البنية التحتية والاستثمارات",
        text: "<p>نحن نعمل بإجتهاد مع العديد من الشركاء الدوليين الرائدين لتطوير مشاريع البنية التحتية الكبيرة والاستثمارات الاستراتيجية.</p>",
      },
      international:
      {
        title: "الشركاء الدوليون",
        text: "<p>سيكون للشركاء الدوليين دور أساسي في توفير الخبرة لمساعدة البلاد، ونحن نعمل بشكل وثيق مع مثل هذه الشركات للاستثمار في ليبيا.</p>",
      },
      knowledge:
      {
        title: "المعرفة المحلية",
        text: "<p>نحن نزود الشركاء التجاريين بالمعرفة المحلية، وشبكة مراجع متاكملة، والدعم التشغيلي الميداني ورأس المال.</p>",
      },
    },
    subtitle: "في شركة سيفيروس ليبيا القابضة، نعمل على تحقيق الأهداف الحقيقية لليبيا",
    title: "معلومات عنا",
    titleImage: "about-us.ar.svg",
  },
  contactUs:
  {
    email: "البريد الالكتروني",
    generalInquiries: "الاستفسارات العامة:",
    message: "رسالة",
    name: "الإسم",
    number: "رقم التواصل",
    send: "إرسال",
    subject: "الموضوع",
    title: "اتصل بنا",
    titleImage: "contact-us.ar.svg"
  },
  ourHistory:
  {
    description: "<p>تأسست مجموعة ليبيا القابضة في عام 2011 على يد أحمد بن حليم للاستفادة من الفرص الاستثمارية الناشئة التي تطورت في ليبيا بعد الثورة والتي لا تزال تتطور حتى اليوم.</p>",
    title: "تاريخنا",
    titleImage: "our-history.ar.svg",
  },
  ourStrategy:
  {
    mission:
    {
      description: "<p>تتمثل مهمة مجموعة ليبيا القابضة في تطوير مجموعة صناعية مربحة في ليبيا من خلال تركيز استراتيجيتنا على القطاعات التي تعتمد على انتعاش ونمو ليبيا مثل النفط والغاز والتصنيع والبنية التحتية والخدمات اللوجستية.</p>",
      title: "المهمة",
    },
    title: "استراتيجيتنا",
    titleImage: "our-strategy.ar.svg",
    values:
    {
      description: "<p>تسهيل نمو وتطور المشاريع التجارية في ليبيا من خلال تكوين شراكات استراتيجية مع الشركات العالمية والمحلية في الصناعات والقطاعات المستهدفة.</p>",
      title: "القيم",
    },
    vision:
    {
      description: "<p>تتمثل رؤيتنا في لعب دور إيجابي في إعادة إعمار ليبيا من خلال الاستثمار في الأعمال المربحة وإدارتها لصالح أصحاب المصلحة.</p>",
      title: "الرؤية",
    },
  },
  ourTeam:
  {
    beaton:
    {
      name: "جراهام بيتون",
      position: "المدير المالي",
      description: "<p>السيد بيتون هو محاسب قانوني مؤهل يتبع نهجًا تجاريًا لتحسين فعالية الوظائف المالية لتحسين أداء الأعمال.</p><p>يتمتع بخبرة واسعة النطاق، لا سيما في المنظمات المتعددة الجنسيات المعقدة العاملة في الأسواق الحدودية والناشئة، حيث يتمتع بخبرة تزيد عن 20 عامًا، خاصة في قطاعات الطاقة و النفط والغاز، حيث قاد عمليات إنجاز العديد من مشاريع البنية التحتية الكبيرة في بيئات مليئة بالتحديات.</p>",
    },
    edwards:
    {
      name: "ديفيد إدواردز",
      position: "شريك الاستثمار",
      description: "<p>شغل السيد إدواردز عددًا من المناصب العليا على مدار الـ 25 عامًا الماضية، حيث شغل منصب المدير العام والمدير الإداري لقسم ضمن شركات Fortune 500 وFTSE 250. وقد ركزت هذه الأدوار بشكل خاص على الصناعة في توليد الطاقة وسلسلة التوريد والخدمات اللوجستية وقطاع الخدمات.</p><br><p>لقد عاش السيد إدواردز وعمل في جميع أنحاء أفريقيا والشرق الأوسط على مدى السنوات الخمس عشرة الماضية. وهو يركز الآن على ليبيا بالإضافة إلى توليه العديد من الأدوار الاستشارية وغير التنفيذية لمساعدة الشركات الدولية على دخول الأسواق الأفريقية.</p>",
    },
    halim:
    {
      name: "أحمد مصطفى بن حليم",
      position: "مدير شركة سيفيروس ليبيا القابضة",
      description: "<p>يتمتع أحمد بن حليم بمزيج فريد من الخبرة كمصرفي استثماري و يتمتع بتراث ليبي عريق. يشارك في إدارة محفظة متنوعة بأكثر من 7.5 مليار دولار من أصول المُدارة. يقود فريقًا مرنًا ويتمتع بمجموعة من الخبرات في تقديم استثمارات معقدة في بيئات مختلفة.</p>",
    },
    matarangasi:
    {
      name: "نيديا ماتارانجاسي",
      position: "المدير التجاري",
      description: "<p>حصلت السيدة ماتارانجاسي على درجة الماجستير في العلوم (MSc) في المالية وماجستير في الآداب (MA) في إدارة التجارة العالمية. مجال خبرتها هو المشتريات التي تركز على توفير قيادة استثنائية وحلول استراتيجية لتحقيق أقصى</p><br><p>قدر من الربح مع تقليل النفقات التشغيلية والتفاوض على الشروط المواتية وتبسيط قنوات الموردين.</p><br><p>لديها أكثر من 10 سنوات من الخبرة في سلسلة التوريد والمجال التجاري في الشركات العالمية، معظمها يتعامل مع إدارة سلسلة التوريد للمشاريع والاستثمارات الكبيرة.</p>",
    },
    rogers:
    {
      name: "نيل روجرز",
      position: "مدير الخزينة",
      description: "<p>يحمل السيد روجرز مؤهلات متعددة في التجارة مع التخصص في إدارة الأعمال والمالية. وتتمثل كفاءته الأساسية في الإدارة اليومية للعمليات المالية، وتحليل التكاليف، والإدارة العامة. يساهم في توفير هيكل متين لتنظيم الأعمال والإدارة السليمة للموارد النقدية.</p><br><p>ومع مسيرة مهنية مالية تمتد لأكثر من 15 عامًا، فقد شغل عددًا من الأدوار العليا داخل الأقسام المالية لكل من الشركات الخاصة والعامة. ويتمثل دوره ضمن فريق إدارة الخدمات المشتركة في تقديم الدعم في العمليات المالية، مع التركيز بشكل خاص على إدارة النقد وإعداد التقارير المالية.</p>",
    },
    title: "فريقنا",
    titleImage: "our-team.ar.svg",
  },
  portfolioCompanies:
  {
    bisa:
    {
      title: "شركة بنغازي الدولية للتوكيلات الملاحية",
      description: "<p>شركة بنغازي الدولية للتوكيلات الملاحية (بيزا)، هي شركة متخصصة في الشحن البحري والخدمات اللوجستية، و مقرها الرئيسي في بنغازي، حيث تقدم خدماتها في كم من موانئ جليانة وطبرق. بفضل فريق مفعم بالعاطفة، واسع النطاق في المعرفة والخبرة، أصبحت شركة بنغازي الدولية للتوكيلات الملاحية (بيزا) الشريك المفضل للعملاء الذين يبحثون عن الخدمات في شرق ليبيا. نحن بحزم نعتقد أنه لا يوجد عميل كبير جدًا أو صغير، ونحن متخصصون في تطوير علاقات طويلة الأمد مع شركات متعددة الجنسيات وعملاء وكالات دولية. كجزء من مجموعة سفيروس ليبيا القابضة، تقدم شركة بنغازي الدولية للتوكيلات الملاحية (بيزا) للعملاء ميزة الخدمة الشاملة والعروض التي تلبي احتياجاتهم في جميع أنحاء البلاد. من البداية إلى النهاية، تلتزم (بيزا) بتقديم خدمات شحن استثنائية وحلول لوجستية فعالة.</p>",
    },
    ghibli:
    {
      title: "قبلي لخدمات القوى العاملة",
      description: "<p>توفر قبلي القوى العاملة المتخصصة والطاقة المتنقلة وخدمات الأعمال لقطاعات النفط والغاز والطاقة والبناء والاتصالات والخدمات اللوجستية. تمتلك شركة قبلي لخدمات القوى العاملة لها مكاتب في طرابلس وبنغازي ومصراتة.</p>",
    },
    goToWebsite: "الذهاب إلى الموقع",
    intro: "<span class='font-semibold'><span class='text-severus-gold'>منذ عام 2015،</span> قامت شركة <br class='md:hidden'>سيفيروس ليبيا القابضة <br>والشركات الشريكة لها <br class='md:hidden'>بتسهيل نمو وتطوير ما يلي:</span>",
    introOld: "منذ عام 2015، قامت شركة سيفيروس ليبيا القابضة والشركات الشريكة لها بتسهيل نمو وتطوير ما يلي:",
    lcc:
    {
      title: "شركة الاسمنت الليبية",
      description: "<p>هي أكبر منتج للأسمنت في ليبيا. وتمتلك الشركة قدرة إنتاجية تبلغ 2.7 مليون طن عبر 3 مصانع تقع في بنغازي،الهواري والفاتحة في شرق ليبيا. توظف شركة الاسمنت الليبية</p><p>أكثر من 2000 شخص مما يجعلها أكبر جهة توظيف في القطاع الخاص في شرق ليبيا.</p><br><p>تقوم شركة الاسمنت الليبية</p><p>حاليًا بتنفيذ خطة استثمارية واسعة النطاق لتحديث مرافقها الإنتاجية وزيادة طاقتها الإنتاجية.</p>",
    },
    location: "الموقع",
    lorasco:
    {
      title: "لوراسكو",
      description: "<p>أسست شركة لوراسكو في عام 2016 لتطوير أعمال رائدة في مجال خدمات الحفر في القطاع الخاص ولخدمة شركات النفط في ليبيا. استحوذت لوراسكو على ثلاث منصات حفر برية وهي بصدد تطوير قاعدة عملائها في ليبيا.</p>",
    },
    mstc:
    {
      title: "شركة مسارات المتوسط للشحن والتوكيلات الملاحية",
      description: "<p>شركة مسارات المتوسط للشحن والتوكيلات الملاحية، هي شركة متخصصة في الشحن البحري والخدمات اللوجستية، و مقرها الرئيسي في طرابلس، حيث تقدم خدماتها في كم من موانئ طرابلس ومصراتة والخمس. بفضل فريق مفعم بالعاطفة، واسع النطاق في المعرفة والخبرة، أصبحت شركة مسارات المتوسط للشحن والتوكيلات الملاحية الشريك المفضل للعملاء الذين يبحثون عن الخدمات في غرب ليبيا. نحن بحزم نعتقد أنه لا يوجد عميل كبير جدًا أو صغير، ونحن متخصصون في تطوير علاقات طويلة الأمد مع شركات متعددة الجنسيات وعملاء وكالات دولية. كجزء من مجموعة سفيروس ليبيا القابضة، تقدم شركة مسارات المتوسط للشحن والتوكيلات الملاحية للعملاء ميزة الخدمة الشاملة والعروض التي تلبي احتياجاتهم في جميع أنحاء البلاد. من البداية إلى النهاية، تلتزم شركة مسارات المتوسط للشحن والتوكيلات الملاحية بتقديم خدمات شحن استثنائية وحلول لوجستية فعالة.</p>",
    },
    readMore: "إقرأ المزيد",
    titleImage: "portfolio-companies.ar.svg",
  },
  siteFooter:
  {
    address:
    {      
      list: "malta",
      malta:
      {
        name: "مالطا",
        number: "هاتف: +356 2122 4361",
        postal: "189، جناح 11، أجنحة مارينا<br>شارع مارينا، بيتا مالط",
      },      
    },
  },
  siteHeader:
  {
    links:
    {
      about: "معلومات عنا",
      aboutLink: "معلومات عنا",
      contact: "اتصل بنا",
      contactLink: "اتصل بنا",
      portfolio: "شركات المحفظة",
      portfolioLink: "شركات المحفظة",
      strategy: "استراتيجيتنا",
      strategyLink: "استراتيجيتنا",
      team: "فريقنا",
      teamLink: "فريقنا",
    }
  },
  videoCarousel:
  {
    banner: "تهدف شركة سيفيروس ليبيا القابضة إلى أن تكون الشريك الاستثماري<br>للشركات العالمية الرائدة الراغبة في خدمة السوق الليبي.",
    desktop:
    {
      bisa:
      {
        image: "bisa.ar.svg",
      },
      ghibli:
      {
        image: "ghibli.ar.svg",
      },
      lcc:
      {
        image: "lcc.ar.svg",
      },
      lorasco:
      {
        image: "lorasco.ar.svg",
      },
      mstc:
      {
        image: "mstc.ar.svg",
      },
    },
    mobile:
    {
      bisa:
      {
        button:
        {
          left: "auto",
          right: "11%",
        },
        image: "bisa.m.ar.svg",
      },
      ghibli:
      {
        image: "ghibli.m.ar.svg",
      },
      lcc:
      {
        button:
        {
          left: "auto",
          right: "11%",
        },
        image: "lcc.m.ar.svg",
      },
      lhs:
      {
        video: "lhs.m.ar.mp4",
      },
      lorasco:
      {
        image: "lorasco.m.ar.svg",
      },
      mstc:
      {
        image: "mstc.m.ar.svg",
      },
    },
    readMore: "إقرأ المزيد",
  },
  whyLibya:
  {
    reasons:
    {
      broadAndDeep:
      {
        description: "<p>إن الفرصة المتاحة في ليبيا واسعة وعميقة على حد سواء، حيث تم إهمال الاستثمار في البلاد لعقود في عدد من القطاعات الصناعية الرئيسية.</p><br><p>ليبيا لديها ثروة من الموارد الطبيعية لها مستقبل في تطويرها وإعادة إعمارها بعد الثورة الأخيرة.</p><br><p>تحتاج ليبيا إلى مئات المليارات من الدولارات من الاستثمارات على مدى العقد المقبل، وتحتاج إلى قدرات وتكنولوجيا الشركات الأجنبية لتحقيق التقدم الاجتماعي والاقتصادي الذي يتطلبه شعبها.</p>",
        title: "فرصة واسعة<br>وعميقة",
      },
      lowCompetition:
      {
        description: "<p>لا توجد طبقة تجارية تذكر على المستوى المحلي في ليبيا بسبب الطبيعة الشمولية للنظام السابق.</p><br><p>وهذا يعني أن هناك عددًا قليلاً من المنافسين محليًا الذين يتمتعون بالقدرات اللازمة لاغتنام الفرص في ليبيا.</p><br><p>ستتطلب القوانين المحلية أن تعمل معظم الشركات مع شريك محلي وتكون الخيارات المتاحة لها غير كافية للطلب.</p>",
        title: "منافسة<br>منخفضة",
      },
      oilCountry:
      {
        description: "<p>تعتمد ليبيا على قطاع النفط في 95% من عائدات التصدير، و25% من ناتجها المحلي الإجمالي، و80% من إيرادات الحكومة، ولكن لم يتم استكشاف سوى 25% من أراضيها حتى الآن.</p><br><p>قطاعها النفطي متخلف حيث كان الإنتاج في انخفاض خلال معظم السنوات الأربعين الماضية ولم يتم استخدام تقنيات الإنتاج المتقدمة.</p><br><p>قطاع البتروكيماويات ليس متطورًا مثل سوق النفط والغاز في البلاد.</p>",
        title: "بلد النفط",
      },
      strategicLocation:
      {
        description: "<p>موقع ليبيا مع أكثر من 2000 كيلومتر من الساحل المواجه لأوروبا له أهمية استراتيجية. وتتمتع بإمكانية الوصول إلى الأسواق غير الساحلية الكبيرة والمتنامية في أفريقيا، جنوب الصحراء الكبرى، فضلاً عن كونها تبعد 280 كيلومتراً فقط عن الاتحاد الأوروبي. وهذا يجعل من ليبيا مركز الاستثمار الطبيعي الذي يربط بين العالم المتقدم والعالم النامي.</p>",
        title: "موقع<br>استراتيجي",
      },
    },
    title: "Why Libya?",
    titleImage: "why-libya.ar.svg",
  },
};
