<script lang="ts" setup>
import { computed } from "vue";

const props = defineProps({ angle: Number });

function angleToPercent(theta = 0, base = 1)
{
  const radians = (theta * Math.PI) / 180;
  return Math.tan(radians) * base * 50;
}

const height = computed(() => `${angleToPercent(props.angle ?? 0)}%`);
const heightDouble = computed(() => `${angleToPercent(props.angle ?? 0) * 2}%`);
const heightInv = computed(() => `${angleToPercent(-(props.angle ?? 0))}%`);

const theta = computed(() => `${props.angle ?? 0}deg`);
const thetaInv = computed(() => `${-(props.angle ?? 0)}deg`);
</script>

<template>
  <div class="outer-skew overflow-hidden">
    <div class="inner-skew relative">
      <div class="absolute bottom-0 left-0 right-0 top-0 -z-[1]">
        <slot name="background"></slot>
      </div>
      <div class="foreground">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<style scoped>
.inner-skew
{
  margin-bottom: v-bind(heightInv);
  margin-top: v-bind(heightInv);
  padding-bottom: v-bind(heightDouble);
  padding-top: v-bind(heightDouble);
  transform: skewY(v-bind(theta));
}

.outer-skew
{
  margin-bottom: v-bind(heightInv);
  margin-top: v-bind(heightInv);
  transform: skewY(v-bind(thetaInv));
}
</style>
