<script lang="ts" setup>
import { ref } from "vue";

const props = defineProps(
{
  label: String,
  modelValue: String,
  name: String,
});

const internalValue = ref(props.modelValue);
</script>

<template>
  <div class="relative">
    <input
      autocomplete="off"
      class="
        peer h-14 px-4 pt-4 w-full rounded-sm
        bg-white focus:outline-none"
      @input='$emit("update:modelValue", internalValue)'
      :name="name"
      placeholder=""
      type="text"
      v-model="internalValue" />
    <label
      :for="name"
      class="
        absolute left-4 right-4 top-1.5 transition-all
        text-xs text-severus-blue pointer-events-none
        peer-focus:text-xs
        peer-focus:top-1.5
        peer-placeholder-shown:text-base
        peer-placeholder-shown:top-4">
      {{ label }}
    </label>
  </div>
</template>
