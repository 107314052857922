<script lang="ts" setup>
import { ref } from "vue";

defineProps(
{
  description: String,
  linkedin: String,
  name: String,
  picture: String,
  pictureMobile: String,
  position: String,
});

const expanded = ref(false);

const divRef = ref<HTMLDivElement>(null as any);

function close() { expanded.value = false; }
function open() { expanded.value = true; }

function scrollIntoViewTimeout(ms: number)
{
  setTimeout(() =>
  {
    divRef.value.scrollIntoView({ behavior: "smooth", block: "center" });
  }, ms);
}

defineEmits<{ (e: "expand", state: boolean): void }>();

defineExpose({ close, open });
</script>

<template>
  <div
    class="aspect-[1.9] md:aspect-[0.76] w-full md:max-w-xs overflow-visible relative">
    <div
      class="bg-white m-2 p-2 rounded-sm shadow-md md:max-w-xs absolute"
      ref="divRef">
      <div class="grid max-md:grid-cols-2 gap-2">
        <img
          class="md:hidden aspect-square rounded-sm"
          :src="`/images/team/${pictureMobile}`">
        <img
          class="max-md:hidden aspect-[1.2] flex-1 w-full rounded-sm"
          :src="`/images/team/${picture}`">
        <!-- info -->
        <div
          class="flex flex-col">
          <div class="grow max-h-8"></div>
          <h3 class="
            text-severus-blue text-xl font-light leading-tight ml-2 md:px-4 md:pt-4">
            {{ name }}
          </h3>
          <h4 class="text-severus-blue text-sm font-bold italic ml-2 md:px-4">
            {{ position }}
          </h4>
          <div class="grow"></div>
          <s-expand class="max-md:hidden">
            <div v-show="expanded">
              <p class="m-6 text-sm" v-html="description"></p>
            </div>
          </s-expand>
          <a
            class="mb-2 md:text-right"
            :href='linkedin !== "" ? linkedin : undefined'
            rel="noopener noreferrer"
            target="_blank">
            <i
              class="mdi mdi-linkedin text-[40px] leading-[40px]"
              :class='linkedin === "" ? "opacity-0" : "text-severus-blue-light"'></i>
          </a>
          <button
            @click='() =>
            {
              scrollIntoViewTimeout(150);
              expanded = !expanded;
              $emit("expand", expanded);
            }'
            class="
              h-9 text-white max-md:rounded-sm
              md:h-8 md:-mx-2 md:-mb-2 md:rounded-b-sm
              bg-gradient-to-r from-severus-blue-light to-severus-blue">
            <i
              class="mdi text-3xl md:text-2xl"
              :class='expanded ? "mdi-chevron-up" : "mdi-chevron-down"'></i>
          </button>
        </div>
      </div>
      <s-expand class="md:hidden">
        <div v-show="expanded">
          <p class="m-10 text-sm" v-html="description"></p>
        </div>
      </s-expand>
    </div>
  </div>
</template>
