<script lang="ts" setup>
import { useLocale } from "vuetify";

const { current, t } = useLocale();
</script>

<template>
  <footer class="text-white bg-severus-blue overflow-auto lang-en">
    <img
      alt="LH Severus logo"
      class="
        mt-10 mb-5 px-16 w-full
        sm:mx-auto sm:max-w-sm"
      src="/images/lh-severus-logo-white.svg">

      <div class="border-severus-white"></div>

    <div
      class="
        max-lg:[&>:not(:first-child)]:border-t
        mx-[15%] pt-8 pb-20 text-sm
        lg:mx-auto lg:flex items-stretch justify-center"
      :class='current === "ar"
        ? "lg:[&>:not(:first-child)]:border-r"
        : "lg:[&>:not(:first-child)]:border-l"'>
      <address
        v-for='address in t("siteFooter.address.list").split(",")'
        class="address"
        :key="address">
        <h3 class="text-center font-bold" v-html="t(`siteFooter.address.${address}.name`)"></h3>                
        <p class="text-center" v-html="t(`siteFooter.address.${address}.number`)"></p>
        <p class="text-center" v-html="t(`siteFooter.address.${address}.postal`)"></p>
      </address>
    </div>
  </footer>
</template>

<style scoped>
.address { @apply px-4 py-10 lg:px-16 lg:py-4 not-italic border-white/70; }
</style>
