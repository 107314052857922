import type { ThemeDefinition } from "vuetify";

export const severusDark: ThemeDefinition =
{
  colors:
  {
    primary: "#f00",
    secondary: "#f0f",
  },
  dark: true,
};

export const severusLight: ThemeDefinition =
{
  colors:
  {
    primary: "#ff0",
    secondary: "#0ff",
  },
  dark: false,
};
