import { createI18n } from "vue-i18n";

import ar from "@/i18n/ar";
import en from "@/i18n/en";
import it from "@/i18n/it";

const i18n = createI18n(
{
  fallbackLocale: "en",
  legacy: false,
  locale: "en",
  messages: { ar, en, it },
});

export default i18n;

export const languages =
[
  { code: "ar", name: "عربي" },
  { code: "en", name: "English" },
  { code: "it", name: "Italian" },
];
